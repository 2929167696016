window.SUB = window.SUB || {};

SUB.el = {
	html : $('html')
};

$(function() {
	SUB.checkMobile();
	SUB.nav.init();
	SUB.Tracking.init();
	$(window).resize(
		_.throttle(SUB.checkMobile, 250)
	);
});

SUB.checkMobile= function(){
	// add "mobile" class if it's a mobile phone
	// used to create the "rotate your phone" message
	// if mobile user is viewing site in landscape orientation
	if(jQuery.browser.mobile){
		SUB.el.html.addClass('mobile');
	} else{
		SUB.el.html.removeClass('mobile');
	}
};


SUB.nav = {
	init : function(){
		var $header =  $('header');
		$header.find('.mobile_nav').click(function(e){
			e.preventDefault();
			var $nav =$header.find('span.nav');
			$nav.toggleClass('open');
		})
	}
};


SUB.Tracking = {
	init: function () {
		// link tracking (finds and tracks links with class="track category|action|label" applied)
		$("body").on("click", "a.track", function (e) {
			var ev = getEventString(this.className),
				target = null,
				callback = null,
				href = null;
			if (ev) {
				if (this.className.match(/action/)) { // use "action" rather than "event" tracking
					ev = "/" + ev.replace(/\|/g, "/");
				}
				target = this.target;
				if (!target || target.match(/^_(self)|(top)$/i) ) {
					href = $(e.target).closest("a")[0].href;
					callback = function () {
						if ( !href.match(/#$/) ) {
							if ( target.match(/^_top$/i) ) {
								top.location.href = href;
							} else {
								location.href = href;
							}
						}
					};
					e.preventDefault();
				}
				BGC.track(ev, callback);
			}
		});
		function getEventString (s) {
			var ev = "";
			s = s.split(" ");
			$.each(s, function () {
				if (this.indexOf("|") > -1) {
					ev = this.toString();
					return;
				}
			});
			return ev;
		};
	}//end init()
};



SUB.videoPrep = {
	init : function(){
		var $videoLinks = $('.video_link'),
			$videoClose = $('#video_close'),
			$overlay = $('#video_overlay');

	// if Youtube is not already loaded,
		if (!window['YT']){
			// load YouTube iFrame API asynchronously: https://developers.google.com/youtube/iframe_api_reference
			var tag = document.createElement('script');

			tag.src = "https://www.youtube.com/iframe_api";
			var firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}

	// respond to Youtube onready event
		function onYouTubeIframeAPIReady() {
			// respond to published events
			var handle = $.subscribe('expandRequested', function (el) {
				$('iframe[src*="www.youtube.com/embed/"]').each(function(i) {
					this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
				});
			});
		};

		$videoLinks.each(function(){
			var $this = $(this);
			$this.click(function(e){
				e.preventDefault();
				SUB.video($this.attr('data-ytid'), $this.attr('data-playlist'));
			});
		});

		$videoClose.click(function(e){
			e.preventDefault();
			SUB.overlay(false, 'close_button');
		});

		$overlay.click(function(e){
			e.preventDefault();
			SUB.overlay(false, 'overlay_click');
		});

	}
};


SUB.video = function(ytID, playlist){
	var $container = $('#video_container'),
		$iframeTarget = $container.find('.iframe_target'),
		id_string =  'vid_' + ytID;

	$iframeTarget.attr('id', id_string);
	SUB.ytSetup(id_string, ytID, playlist, 1, false, false, true);

	SUB.overlay(true, ytID);
};

SUB.overlay =  function(open, trackingString){
	var $overlay = $('#video_overlay'),
		$container =  $('#video_container'),
		action = "";
	if(open === true){
		$overlay.fadeIn(100);
		action = "launch_overlay";
	} else{
		$overlay.hide(1);
		action = "close_overlay";
		$container.append('<div class="iframe_target"></div>').find('iframe').remove();  // wipe out target's html, destroy player
	}
	//GTM tracking
	dataLayer.push({event: 'event', category: 'video', action: action, label: trackingString});
};

SUB.ytSetup = function(id_string, ytID, playlist, autoPlay, allowRelated, loop, modestBranding){

	// otherwise, set up as single player
	var newVideo = new YT.Player(id_string, {
		height: '100%',
		width: '100%',
		videoId: ytID,
		events: {
			'onStateChange': SUB.ytTrack
		},
		playerVars:{
			autoplay: autoPlay,
			enablejsapi : 1,
			loop: loop,
			modestbranding: modestBranding,
			playlist: playlist,
			rel: allowRelated
		}
	});

};

SUB.ytTrack= function(e){
	/*
	 YT.PlayerState.ENDED
	 YT.PlayerState.PLAYING
	 YT.PlayerState.PAUSED
	 YT.PlayerState.BUFFERING
	 YT.PlayerState.CUED
	 */

	var video = e.target.getVideoData();

	if(e.data === YT.PlayerState.PLAYING){
		dataLayer.push({event: 'event', category: 'video', action: 'start', label:$('.iframe_target').attr('data-ytid')});
	} else if (e.data === YT.PlayerState.ENDED){
		dataLayer.push({event: 'event', category: 'video', action: 'finish', label:$('.iframe_target').attr('data-ytid')});
	}
};







